import React from "react";
import styles from "./RiskButton.module.sass";
import cn from "classnames";

export default function RiskButton(props) {
  const { variant } = props;
  return (
    <p
      className={cn(styles.button, {
        [styles.lowRiskStyle]: variant === 0,
        [styles.middleRiskStyle]: variant === 1,
        [styles.highRiskStyle]: variant === 2,
      })}
      style={{ display: "inline-block" }}
    >
      {variant === 0 && "Az İlişkili"}
      {variant === 1 && "İlişkili"}
      {variant === 2 && "Yüksek İlişkili"}
    </p>
  );
}
