import React from "react";
import styles from "./NotFound.module.sass";

export default function NotFound() {
  return (
    <div className={styles.photo}>
      <img src="/images/logo.png" alt="Aradığınız sayfa bulunamadı" />
      <p className={styles.text}>Aradığınız sayfa bulunamadı</p>
    </div>
  );
}
