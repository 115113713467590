// Main.jsx

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Panel from "../../../components/Panel";
import Location from "../../../components/Location";
import Travelers from "../../../components/Travelers";
import brandService from "../../../services/BrandService";
// import { ReactComponent as CoinIcon } from '../../../../public/images/coin.svg';
const Main = ({ categories }) => {
  const [keyword, setKeyword] = useState("");
  const [visible, setVisible] = useState(false);
  const [brands, setBrands] = useState([]);
  const [activeButton, setActiveButton] = useState(0);

  const removeTurkishChars = (text) => {
    const turkishChars = "ğĞüÜşŞıİöÖçÇ";
    const englishChars = "gGuUsSiIoOcC";

    for (let i = 0; i < turkishChars.length; i++) {
      const regex = new RegExp(turkishChars[i], "g");
      text = text.replace(regex, englishChars[i]);
    }

    return text;
  };

  const filterCategory = (categories, query) => {
    const queryLowerCase = removeTurkishChars(query.keyword.toLowerCase());
    return categories.filter((category) =>
      removeTurkishChars(category.name.toLowerCase()).includes(queryLowerCase)
    );
  };

  const fetchSearch = async (search) => {
    const query = {
      keyword: search ? search : keyword,
    };

    if (activeButton === 0) {
      //Markalar
      const result = await brandService.getBrandBySearch(query.keyword);
      setVisible(true);
      if (result?.status) {
        setBrands(result.data);
      } else {
        console.log("gelmedi veri");
      }
    } else if (activeButton === 1) {
      //Kategoriler
      setVisible(true);
      setBrands(filterCategory(categories, query));
    }
  };

  useEffect(() => {
    if (keyword.length === 0) {
      setBrands([]);
    }
  }, [keyword]);

  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    const updatePageWidth = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", updatePageWidth);
    updatePageWidth();

    return () => {
      window.removeEventListener("resize", updatePageWidth);
    };
  }, []);

  return (
    <div className={cn(styles.section)}>
      <div className={cn("container", styles.container)}>
        <div>
          <div className={styles.preview}>
            <div className={styles.wrap}>
              <h2
                className={cn("stolzl-text", styles.titleWrap)}
                style={{ color: "white" }}
              >
                <span>Soykırımın</span>
                <span style={{ color: "#EA1821" }}>finansörü</span>
                <span>
                  olma!{" "}
                  <img
                    alt=""
                    src={`/images/coin.svg`}
                    style={{ width: "36px", height: "44px" }}
                  />{" "}
                </span>
              </h2>

              <div
                className={cn(styles.description, styles.info, "poppins-text")}
                style={{ color: "white" }}
              >
                Savaşın günlük maliyeti 246 milyon dolar, bu onlar için bile
                sürdürülebilir değil. Sen desteklemezsen savaşa dur diye
                bilirsin.
              </div>
            </div>

            <div className={styles.headerImagesContainer}>
              <img
                src="/images/header-1.png"
                className={styles.headerImages}
                alt=""
              />

              <img
                src="/images/header-2.png"
                className={styles.headerImages}
                alt=""
              />

              <img
                src="/images/header-3.png"
                className={styles.headerImages}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className={styles.panelWrapper}>
          <Panel
            className={styles.panel}
            menu
            activeButton={activeButton}
            setKeyword={setKeyword}
            setBrands={setBrands}
            setActiveButton={setActiveButton}
            classBody={styles.body}
            onSearch={() => {
              fetchSearch();
              setVisible(true);
            }}
          >
            <div className={styles.full}>
              <Location
                setBrands={setBrands}
                className={styles.location}
                keyword={keyword}
                setKeyword={setKeyword}
                brands={brands}
                visible={visible}
                setVisible={setVisible}
                icon="location"
                activeButton={activeButton}
                fetchSearch={fetchSearch}
                description={
                  pageWidth > 760
                    ? "Boykot listesinde ki markaları öğrenmek için arama yapabilirsiniz?"
                    : ""
                }
                placeholder={
                  activeButton === 0
                    ? "Aramak istediğiniz markayı yazın"
                    : "Aramak istediğiniz kategoriyi yazın"
                }
              />
            </div>
          </Panel>
        </div>

        <img
          src="/images/header-wire.png"
          className={styles.headerWire}
          alt=""
        />

        <img
          src="/images/header-wire-right.png"
          className={styles.headerWireRigt}
          alt=""
        />
      </div>
    </div>
  );
};

export default Main;
