import axios from "axios";
const apiUrl = "https://api.destekleme.com";

const baseService = axios.create({
  baseURL: apiUrl,
  headers: {
    "x-boycot-access-token":
      "c25608a967a97fbe7e668d5a64f3005bfd2c77ab7482ef21f0cb0a4a1db5bef3",
    "api-access-token":
      "e84bb44f9adfa23516c97710d57d887a617f4f4a59bf588ff5d019918d6e7de6",
  },
});

export default baseService;