import React, { useEffect, useState } from "react";
import Main from "./Main";
import Catalog from "./Catalog";
import Planning from "../../components/Planning";
import Places from "../../components/Places";
// data
import categoryService from "../../services/CategoryService";
const Home = () => {
  const [categories, setCategories] = useState([]);
  console.log(categories);

  const fetchCategories = async () => {
    const result = await categoryService.getCategories();
    if (result) {
      setCategories(result.filter((category) => category.count !== 0));
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      <Main categories={categories} />
      {/* <Live title="Live anywhere" /> */}
      <Places
        items={categories}
        title="Kategoriler"
        info="Markalara kategorilere göre"
      />
      <Planning title="Ürünü tarat, boykot listesinde mi öğren" />
      <Catalog />
      {/* Son eklenenler */}
      {/* <Browse
        classSection="section"
        classTitle="h2"
        title="Boykot İçerikleri"
        info="Yaşanan gelişmeleri güncel takip edin"
        items={browse1}
      /> */}
    </>
  );
};

export default Home;
