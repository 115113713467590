import React, { useState } from "react";
import cn from "classnames";
import styles from "./Planning.module.sass";
import Image from "../Image";
import Form from "../Form";
import brandService from "../../services/BrandService";
const gallery = [
  {
    src: "/images/tarat.png",
    srcSet: "/images/tarat.png",
    srcDark: "/images/tarat.png",
    srcSetDark: "/images/tarat.png",
  },
];

const Planning = ({ classSection, title }) => {
  const [barcode, setBarcode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await brandService.getScanByCode(barcode);

    if(result?.status){
    return alert(result?.data?.brand_id?.name);
    }

    if(!result?.status){
      return alert(result?.message)
    }

    // console.log(result.brand_id.name,"result.brand_id.name")
  };

  return (
    <div className={cn("section", classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
          Mobil Uygulamadan Hızlıca Ulaşın
          </div>
          <h2 className={cn("h2", styles.title)}>{title}</h2>
          <div className={styles.info}>
          Yakında yayınlayacağımız mobil uygulamamız hızlıca boykot listesine erişebilir, ürünleri kameraya göstererek boykot listesi kontrolü yapabilirsiniz.
          </div>
          <Form
            className={styles.form}
            value={barcode}
            setValue={setBarcode}
            onSubmit={(e) => handleSubmit(e)}
            placeholder="Barkod numaranızı giriniz."
            type="text"
            inputMode="numeric"
            name="phone"
            icon="arrow-next"
          />
        </div>
        <div className={styles.gallery}>
          {gallery.map((x, index) => (
            <div className={styles.preview} key={index}>
              <Image
                srcSet={`${x.srcSet} 2x`}
                srcSetDark={`${x.srcSetDark} 2x`}
                src={x.src}
                srcDark={x.srcDark}
                alt="Planning"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Planning;
