import React from "react";
import cn from "classnames";
import styles from "./Control.module.sass";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../Icon";
import Breadcrumbs from "../Breadcrumbs";

const Control = ({ className, urlHome, breadcrumbs }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={cn(className, styles.control)}>
      <button
        className={cn(styles.button, styles.buttonStroke, styles.buttonSmall)}
        onClick={handleGoBack}
      >
        <Icon name="arrow-left" size="10" />
        <span className={styles.buttonTitle}>Geri</span>
      </button>
      {/* <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} /> */}
    </div>
  );
};

export default Control;
