import React, { useState } from "react";
import cn from "classnames";
import styles from "./Footer.module.sass";
import { Link } from "react-router-dom";
import Image from "../Image";
import Icon from "../Icon";
import Theme from "../Theme";
import Form from "../Form";

const items = [
  {
    title: "Kategoriler",
    url: "/kategoriler",
  },
  // {
  //   title: "Kozmetik",
  //   url: "/flights",
  // },
  // {
  //   title: "Support",
  //   url: "/support",
  // },
  // {
  //   title: "Cars",
  //   url: "/cars",
  // },
  {
    title: "Bir hata mı var?",
    url: "/iletişim",
  },
  {
    title: "Hemen İndir",
    url: "/",
  },
];

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <div className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <Link className={styles.logo} to="/">
            <img
              className={styles.pic}
              src="/images/logo-dark.svg"
              alt="Destekleme"
            />
          </Link>

          <div className={styles.menu}>
            {items.map((x, index) => (
              <Link className={styles.link} to={x.url} key={index}>
                {x.title}
              </Link>
            ))}

            <img src="/images/play-store.svg" alt="" className={styles.icon2} />
            <img src="/images/app-store.png" alt="" className={styles.icon} />
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.copyright}>
            Copyright © 2023 Destekleme Platformu. Tüm hakları saklıdır
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
