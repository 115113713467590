import React from "react";
import cn from "classnames";
import styles from "./Actions.module.sass";
import Share from "../Share";

const Actions = ({ className }) => {
  return (
    <div className={cn(className, styles.actions)}>
      <div className={styles.list}>
        <Share />
       
      </div>
    </div>
  );
};

export default Actions;
