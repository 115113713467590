import React from "react";
import cn from "classnames";
import styles from "./Receipt.module.sass";
import Icon from "../Icon";

const Receipt = ({ className, item, children }) => {
  return (
    <div className={cn(className, styles.receipt)}>
      <div className={styles.head}>
        <div className={styles.flex}>
          <img src={item?.logo} alt={item?.name} className={styles.imgc} />
          <div className={styles.check}>
            <Icon name="check" size="12" />
          </div>
        </div>
      </div>

      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default Receipt;
