import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import Card from "./Card";
import brandService from "../../../services/BrandService";
// data
import { catalogList } from "../../../mocks/catalog";
import Loader from "../../../components/Loader";

const dateOptions = ["En son eklenen", ""];
const sortingOptions = [];

const Catalog = () => {
  const [value, setValue] = useState("Hepsi");
  const [allLastAddeds, setAllLastAddeds] = useState([]);
  const [active, setActive] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBrands = async (category, page) => {
    setIsLoading(true);
    const res = await brandService.getAllBrandsByCategoryAndLimit(
      category,
      12,
      page
    );
    setAllLastAddeds((prevData) => [...prevData, ...res.data.data]);
    if (currentPage >= res.data.totalPages) {
      setIsLastPage(true);
    } // Append new data to existing data
    setIsLoading(false);
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1); // Increment the current page
  };

  useEffect(() => {
    setAllLastAddeds([]);
    setCurrentPage(1);
  }, [active]);

  useEffect(() => {
    const fetchData = async () => {
      if (active === 0) {
        await getBrandsByLimit(12, currentPage);
      } else if (active === 1) {
        await fetchBrands("654582d5c10e250e440bdde5", currentPage);
      } else if (active === 2) {
        await fetchBrands("6545828ec10e250e440bdde1", currentPage);
      } else if (active === 3) {
        await fetchBrands("6545823cc10e250e440bdddd", currentPage);
      }
    };

    fetchData();
  }, [active, currentPage]);

  // rastgele kategorilerde en son eklenen 12 ürün için
  const getBrandsByLimit = async (limit, currentPage) => {
    setIsLoading(true);
    const res = await brandService.getAllBrandsByLimit(limit, currentPage);
    if (currentPage >= res.data.totalPages) {
      setIsLastPage(true);
    }
    setAllLastAddeds((prevData) => [...prevData, ...res.data.data]);
    setIsLoading(false);
  };

  const [sorting, setSorting] = useState(sortingOptions[0]);

  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    const updatePageWidth = () => {
      setPageWidth(window.innerWidth);
    };

    // Sayfa yüklendiğinde ve pencere boyutu değiştiğinde sayfa genişliğini güncelle
    window.addEventListener("resize", updatePageWidth);
    updatePageWidth(); // Sayfa ilk yüklendiğinde genişliği güncelle

    // Temizleme işlemi
    return () => {
      window.removeEventListener("resize", updatePageWidth);
    };
  }, []); // Boş bağımlılık dizisi sayesinde sadece bir kere çalışır

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <h2 className={cn("h2", styles.title)}>Son Eklenenler</h2>
          <div className={cn("info", styles.info)}>
            En son eklenen markaları takip edebilirsiniz
          </div>
          <div className={styles.sorting}>
            <div className={styles.nav}>
              {catalogList.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === active,
                  })}
                  onClick={() => {
                    setActive(index);
                    setIsLastPage(false);
                  }}
                  key={index}
                >
                  <Icon name={x.icon} size="16" />
                  {x.title}
                </button>
              ))}
            </div>
            {/* <div className={cn("tablet-show", styles.box)}>
              <Dropdown
                className={styles.dropdown}
                value={sorting}
                setValue={setSorting}
                options={sortingOptions}
              />
            </div> */}

            {pageWidth <= 760 && (
              <div className={styles.box}>
                <Dropdown
                  className={styles.dropdown}
                  setActive={setActive}
                  value={value}
                  setValue={setValue}
                  options={catalogList}
                />
              </div>
            )}
          </div>

          <div className={styles.list}>
            {allLastAddeds.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </div>

          {!isLastPage && (
            <div className={styles.btns}>
              <button
                className={cn(
                  "button-small",
                  styles.button,
                  styles.buttonStroke
                )}
                onClick={handleLoadMore}
              >
                {!isLoading && !isLastPage && "Daha Fazla Göster"}
                {isLoading && (
                  <>
                    <Loader className={styles.loader} color="white" />
                    <span>Yükleniyor...</span>
                  </>
                )}
              </button>
            </div>
          )}

          {!isLoading && isLastPage && (
            <div className={styles.allBrandsShowedButton}>
              <span className={cn(styles.button)} disabled={true}>
                Tüm markalar gösterilmiştir
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Catalog;
