import React, { useEffect, useState } from "react";
import Main from "../CarsCategory2/Main2";
import Catalog from "./Catalog";
import Browse from "../../components/Browse";
import Categories from "../../components/Categories";

// data
import { browse3 } from "../../mocks/browse";
import { categories2 } from "../../mocks/categories";
import { useParams } from "react-router";
import categoryService from "../../services/CategoryService";
import Places from "../../components/Places";

const Cars = () => {
  const [category, setCategory] = useState(null);

  const fetchCategory = async () => {
    const result = await categoryService.getCategories();
    setCategory(result);
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <>
      <Main categories={category} />
      <Places
        items={category}
        title="Kategoriler"
        info="Markalara kategorilere göre"
      />
    </>
  );
};

export default Cars;
