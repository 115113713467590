import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Modal from "../Modal";
import Login from "../Login";

const Header = ({ separatorHeader, wide, notAuthorized }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        className={cn(
          styles.header,
          { [styles.wide]: wide }
        )}
      >
        <div className={cn("container", styles.container)}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="/images/logo.svg"
              alt="Destekleme"
            />
          </Link>

          <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
            <Dropdown
              className={styles.drowdown}
              // items={categories}
              setValue={setVisibleNav}
            />
          </div>

          <NavLink
            className={cn(styles.link, styles.mobileLink)}
            to="/support"
            activeClassName={styles.active}
          >
            Hakkımızda
          </NavLink>

          <NavLink
            className={cn(styles.link, styles.mobileLink)}
            to="/iletişim"
            activeClassName={styles.active}
          >
            Bir Hata mı var?
          </NavLink>

          <div className={styles.mobileHeader}>
            <NavLink
              className={cn("button-stroke button-small", styles.button)}
              activeClassName={styles.active}
              to="/iletişim"
            >
              Hemen İndir
            </NavLink>

            <button
              className={cn(styles.burger, { [styles.active]: visibleNav })}
              onClick={() => setVisibleNav(!visibleNav)}
            ></button>
          </div>
        </div>
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <Login />
      </Modal>
    </>
  );
};

export default Header;
