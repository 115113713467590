import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { Link } from "react-router-dom";

const Item = ({ item, className }) => {
  return (
    <Link
      className={cn(styles.item, className)}
      to={`/kategori/${item?._id}`}
      style={{ display: "block" }}
    >
      <div className={styles.preview}>
        <img src="/images/brands.svg" alt={item.name} />
      </div>

      <div className={styles.title}>{item.name}</div>
      <div className={styles.time}>
        <button className={cn("button-stroke2 button-small2", styles.button)}>
          {item.count} Marka
        </button>

        <Link className={styles.row}>Git{`>`}</Link>
      </div>
    </Link>
  );
};

export default Item;
