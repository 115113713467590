import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Panel from "../../../components/Panel";
import Location from "../../../components/Location";
import Travelers from "../../../components/Travelers";
import brandService from "../../../services/BrandService";
const Main = ({ categories }) => {
  const [keyword, setKeyword] = useState("");
  const [visible, setVisible] = useState(false);
  const [brands, setBrands] = useState([]);
  const [activeButton, setActiveButton] = useState(0);

  const removeTurkishChars = (text) => {
    const turkishChars = "ğĞüÜşŞıİöÖçÇ";
    const englishChars = "gGuUsSiIoOcC";

    for (let i = 0; i < turkishChars.length; i++) {
      const regex = new RegExp(turkishChars[i], "g");
      text = text.replace(regex, englishChars[i]);
    }

    return text;
  };

  const filterCategory = (categories, query) => {
    const queryLowerCase = removeTurkishChars(query.keyword.toLowerCase());
    return categories.filter((category) =>
      removeTurkishChars(category.name.toLowerCase()).includes(queryLowerCase)
    );
  };

  const fetchSearch = async (search) => {
    const query = {
      keyword: search ? search : keyword,
    };

    if (activeButton === 0) {
      //Markalar
      const result = await brandService.getBrandBySearch(query.keyword);
      setVisible(true);
      if (result?.status) {
        setBrands(result.data);
      } else {
        console.log("gelmedi veri");
      }
    } else if (activeButton === 1) {
      //Kategoriler
      setVisible(true);
      setBrands(filterCategory(categories, query));
    }
  };

  useEffect(() => {
    if (keyword.length === 0) {
      setBrands([]);
    }
  }, [keyword]);

  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    const updatePageWidth = () => {
      setPageWidth(window.innerWidth);
    };

    // Sayfa yüklendiğinde ve pencere boyutu değiştiğinde sayfa genişliğini güncelle
    window.addEventListener("resize", updatePageWidth);
    updatePageWidth(); // Sayfa ilk yüklendiğinde genişliği güncelle

    // Temizleme işlemi
    return () => {
      window.removeEventListener("resize", updatePageWidth);
    };
  }, []); // Boş bağımlılık dizisi sayesinde sadece bir kere çalışır

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet="/images/gazze-tr.jpg"
              style={{
                objectFit: "cover",
              }}
            />
            <img
              src="/images/gazze-tr.jpg"
              alt="Main"
              className={styles.imgContainer}
            />
          </picture>
        
        </div>

        <Panel
          className={styles.panel}
          menu
          activeButton={activeButton}
          setKeyword={setKeyword}
          setBrands={setBrands}
          setActiveButton={setActiveButton}
          classBody={styles.body}
          onSearch={() => {
            fetchSearch();
            setVisible(true);
          }}
        >
          <div className={styles.full}>
            <Location
              setBrands={setBrands}
              className={styles.location}
              keyword={keyword}
              setKeyword={setKeyword}
              brands={brands}
              visible={visible}
              setVisible={setVisible}
              icon="location"
              activeButton={activeButton}
              fetchSearch={fetchSearch}
              description={
                pageWidth > 760
                  ? "Boykot listesinde ki markaları öğrenmek için arama yapabilirsiniz?"
                  : ""
              }
              placeholder={
                activeButton === 0
                  ? "Aramak istediğiniz markayı yazın"
                  : "Aramak istediğiniz kategoriyi yazın"
              }
            />
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default Main;
