import React from "react";
import "./style.css";

export default function Contact() {
  return (
    <section className="bg-white   container">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md w-full">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">
          Bizimle İletişime Geç
        </h2>

        <p className="mb-4 lg:mb-16 font-light text-center text-gray-500  sm:text-xl">
          Teknik bir sorun mu var? Veya bize bir öneride mi bulunmak
          istiyorsunuz?
        </p>

        <form action="#" className="space-y-8">
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              E-posta adresiniz
            </label>
            <input
              type="email"
              id="email"
              className="shadow-sm bg-gray-50 border px-4 py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="iletişim@destekleme.com"
              required=""
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Konu
            </label>
            <input
              type="email"
              id="email"
              className="shadow-sm bg-gray-50 border px-4 py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Size hangi konuda yardımcı olabiliriz?"
              required=""
            />
          </div>

          <div className="">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Mesajınız
            </label>
            <textarea
              id="message"
              rows={6}
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Mesajınızı yazınız..."
              defaultValue={""}
            />
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="py-3 px-8 text-sm  font-medium text-center text-white rounded-lg bg-blue-500 sm:w-fit hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Gönder
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
