import React from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "../Control";
import Actions from "../Actions";

const Product = ({ classSection, urlHome, breadcrumbs, title, categories }) => {
  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <Control
          className={styles.control}
          urlHome={urlHome}
          breadcrumbs={breadcrumbs}
        />
        <div className={styles.head}>
          <div className={styles.box}>
            <h1 className={cn("h2", styles.title)}>{title}</h1>
            <div className={styles.line}>
           
            </div>
          </div>
          <Actions className={styles.actions} />
        </div>
      </div>
    </div>
  );
};

export default Product;
