import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../../../../components/Icon";
import RiskButton from "../../../../components/Risk/RiskButton";

const Card = ({ className, item }) => {
  return (
    <Link
      className={cn(className, styles.card)}
      to={`/marka/${item?._id}`}
      style={{ display: "block" }}
    >
      <div className={styles.preview}>
        <img src={item?.logo} alt="Card" />
      </div>

      <div className={styles.body}>
        <div className={styles.details}>
          <div className={styles.subtitle}>{item?.name}</div>
          <div className={styles.location}>{item?.category[0]?.name}</div>
        </div>

        <div>
          <RiskButton variant={item?.star ?? 0} />
        </div>

        <button className={styles.button}>
          <Icon name="arrow-next" className={styles.arrow} size={20} />
        </button>
      </div>
    </Link>
  );
};

export default Card;
