import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../Icon";

const Item = ({ className, item, row, car }) => {
  return (
    <Link className={cn(className, styles.card)} to={`/marka/${item?._id}`}>
      <div className={styles.preview}>
        <img src={item?.logo} alt="Card" />
      </div>
      <div className={styles.body}>
        <div className={styles.details}>
          <div className={styles.subtitle}>{item?.name}</div>
          <div className={styles.location}>{item.category[0]?.name}</div>
        </div>
        <div className={`${styles.flex}`}>
          <p
            className={styles.lowRiskStyle}
            style={{ display: "inline-block" }}
          >
            Az Riskli
          </p>
          <span>Git</span>
        </div>
      </div>
    </Link>
  );
};

export default Item;
