import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink } from "react-router-dom";
import Icon from "../../Icon";
import categoryService from "../../../services/CategoryService";

const Dropdown = ({ className, setValue }) => {
  const [visible, setVisible] = useState(false);
  const [activeButton, setActiveButton] = useState(4);

  const handleClick = (x, index) => {
    setVisible(false);
    setValue(index);
    setActiveButton(index);
  };

  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    const result = await categoryService.getCategories();
    setCategories([
      ...result?.slice(0, 4),
      { name: "Tüm Kategoriler", _id: "kategoriler" },
    ]);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.dropdown, { [styles.active]: visible })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.textDropdown}>
            {activeButton === 4
              ? "Tüm Kategoriler"
              : categories[activeButton]?.name}
          </div>
          <div className={styles.icon}>
            <Icon name="arrow-down" size="24" />
          </div>
        </button>

        <div className={styles.body}>
          {categories?.map((x, index) => (
            <NavLink
              forceRefresh={true}
              className={
                index === activeButton ? styles.activeButton : styles.item
              }
              key={index}
              to={
                x._id === "kategoriler" ? `/kategoriler` : `/kategori/${x?._id}`
              }
              onClick={() => handleClick(x, index)}
              exact
            >
              {x.name === "Tüm Kategoriler" ? (
                <Icon
                  name="home"
                  size="24"
                  className={
                    index === activeButton
                      ? styles.homeIcon
                      : styles.activeHomeIcon
                  }
                />
              ) : (
                <img src={x?.logo} className={styles.imgSize} alt={x?.name} />
              )}
              <div className={styles.text}>{x?.name}</div>
            </NavLink>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
