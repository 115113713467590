import React from "react";
import cn from "classnames";
import styles from "./Description.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Details from "./Details";
import Receipt from "../../../components/Receipt";
import RiskButton from "../../../components/Risk/RiskButton";





const Description = ({ classSection, item }) => {
  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <Receipt className={styles.receipt} item={item}>
            <div className={styles.foot}>
              <h2 className={styles.brandName}>{item?.name}</h2>

              <h5 className={styles.categoryStyle}>
                {item?.category[0]?.name}
              </h5>

              <RiskButton variant={item?.star ?? 0} />

              <div className={styles.foot}></div>
              <button className={styles.report}>
                <Icon name="flag" size="12" />
                Raporla
              </button>
            </div>
          </Receipt>
          <Details className={styles.details} item={item} />
        </div>
      </div>
    </div>
  );
};

export default Description;
