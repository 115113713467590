import axios from "./BaseService";

const getAllBrands = async () => {
  try {
    const response = await axios.get("/api/brands");
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

const getBrandById = async (id) => {
  try {
    const response = await axios.get(`/api/brands/${id}`);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

const getBrandBySearch = async (search) => {
  try {
    const response = await axios.get(
      `/api/brands/brand/search?keyword=${search}`
    );
    return response.data
  } catch (err) {
    console.log(err);
  }
};

const getAllBrandByCategoryId = async (categoryId,page) => {
  try {
    const response = await axios.get(`/api/brands/category/${categoryId}?limit=12${page ? `&page=${page}` : ""}`);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

const getAllBrandsByLimit = async (limit,page=1) => {
  try {
    const response = await axios.get(`/api/brands/?limit=${limit}&sortBy=date&page=${page}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getAllBrandsByCategoryAndLimit = async (categoryId, limit,page=1) => {
  try {
    const response = await axios.get(
      `/api/brands/category/${categoryId}?limit=${limit}&sortBy=date&page=${page}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getScanByCode = async (code) => {

  try {
    if(!code){
      return console.log("code")
    }
    const response = await axios.get(`/api/barcode/scan?code=${code}`);
    return response.data;

  } catch (err) {
    return err.response.data
  }
}

const brandService = {
  getAllBrands,
  getBrandById,
  getBrandBySearch,
  getAllBrandByCategoryId,
  getAllBrandsByLimit,
  getAllBrandsByCategoryAndLimit,
  getScanByCode
};

export default brandService;

/*

Giyim = > 6545823cc10e250e440bdddd
Teknoloji => 6545835cc10e250e440bddee
İçecek => 6545828ec10e250e440bdde1
Yiyecek => 654582d5c10e250e440bdde5
*/