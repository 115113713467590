import axios from "./BaseService";

const getCategories = async () => {
  try {
    const response = await axios.get("/api/categories");
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

const getCategoryById = async (id) => {
  try {
    const response = await axios.get(`/api/categories/${id}`);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

const categoryService = {
  getCategories,
  getCategoryById,
};

export default categoryService;