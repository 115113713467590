import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../../components/Icon";
import { Link } from "react-router-dom";

const parameters = [
  {
    title: "2 guests",
    icon: "home",
  },
  {
    title: "1 bedroom",
    icon: "flag",
  },
  {
    title: "1 private bath",
    icon: "flag",
  },
];

const Details = ({ className, item }) => {
  console.log("ıtem : ", item);
  return (
    <div className={cn(className, styles.details)}>
      <div className={styles.line}>
        <h4 className={cn("h4", styles.title)}>Marka Detayları</h4>
        {/* <div className={styles.parameters}>
          {parameters.map((x, index) => (
            <div className={styles.parameter} key={index}>
              <Icon name={x.icon} size="20" />
              {x.title}
            </div>
          ))}
        </div> */}
      </div>
      <div className={styles.content}>
        <p className={styles.reason}>{item?.reason}</p>

        <div className={styles.decisionContainer}>
          {item?.source?.map((kanit, index) => (
            <>
              <Link to={kanit} target="_blank" className={styles.decision}>
                <Icon name="calendar" size={18} className={styles.icon} />
                Kaynak Bağlantı {index + 1}
              </Link>
            </>
          ))}
        </div>
      </div>

      <div>
        <h4 className={cn("h4", styles.alternativeTitle)}>
          Alternatif Markalar
        </h4>

        <div style={{ display: "flex" }}>
          <div className={styles.alternativeBrandContainer}>
            <div className={styles.alternativeBrandImgCont}>
              <img
                className={styles.alternativeBrandImg}
                src={item?.logo}
                alt="Alternatif Marka"
              />
            </div>

            <div className={styles.alternativeBrandDesc}>
              <h6>Torku</h6>
              <p>Gıda</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={styles.info}>Alternatif Markalar</div>
        
      <button className={cn("button-stroke button-small", styles.button)}>
        More detail
      </button> */}
    </div>
  );
};

export default Details;
