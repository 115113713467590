import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Sorting from "../../../components/Sorting2";
import Card from "../../../screens/Stays/Catalog/Card";
import brandService from "../../../services/BrandService";
import { useParams } from "react-router";
import Loader from "../../../components/Loader";

const navigation = ["Boykot Markaları", "Destek Veren Firmalar"];

const saleOptions = ["Risk Durumuna Göre"];

const Catalog = ({ title, count }) => {
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  console.log(count,"count")
  const fetchCategory = async () => {
    setIsLoading(true);
    const result = await brandService.getAllBrandByCategoryId(id, currentPage);
    console.log(result, "result");
    if(result.data.length === 0){
      setBrands([]);

    }else {
      setBrands((prevBrands) => [...prevBrands, ...result.data]);
      setTotalPages(result.totalPages); // Assuming your service returns this information
    }
    setIsLoading(false);

   
  };

  useEffect(() => {
    fetchCategory();
  }, [id, currentPage]);

  const handleShowMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  return (
    <div className={cn("section-mb80", styles.section)}>
      <Sorting
        className={styles.sorting}
        urlHome="/cars"
        navigation={navigation}
        title={title}
        count={count}
        sortingOptions={saleOptions}
      />
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.list}>
            {brands?.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </div>

          {(currentPage < totalPages && brands.length > 0 ) && (
            <div className={styles.btns}>
              <button
                className={cn(
                  "button-small",
                  styles.button,
                  styles.buttonStroke
                )}
                onClick={handleShowMore}
              >
                {!isLoading && "Daha Fazla Göster"}
                {isLoading && (
                  <>
                    <Loader className={styles.loader} color="white" />
                    <span>Yükleniyor...</span>
                  </>
                )}
              </button>
            </div>
          )}

          {currentPage >= totalPages && (
            <div className={styles.btns}>
              <span
                className={cn(
                  "button-small",
                  styles.button,
                  styles.buttonStroke
                )}
              >
                Tüm Markalar Görüntülenmiştir.
              </span>
            </div>
          )}

{brands.length <= 0 && (
            <div className={styles.btns}>
              <span
                className={cn(
                  "button-small",
                  styles.button,
                  styles.buttonStroke
                )}
              >
                Gösterilecek marka bulunamamıştır.
              </span>
            </div>
          )}
         
        </div>
      </div>
    </div>
  );
};

export default Catalog;
