import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./StaysProduct.module.sass";
import Product from "../../components/Product";
import Description from "./Description";
import CommentsProduct from "../../components/CommentsProduct";
import Browse from "../../components/Browse";
import BrowseBoykot from "../../components/BrowseBoykot2";

import Newsletter from "../../components/Newsletter";

// data
import { browse1 } from "../../mocks/browse";
import { browse2 } from "../../mocks/browse";
import Categories from "../../components/Categories";
import { categories1 } from "../../mocks/categories";
import categoryService from "../../services/CategoryService";
import { useParams } from "react-router";
import brandService from "../../services/BrandService";
import Loader from "../../components/Loader";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Stays",
    url: "/",
  },
  {
    title: "New Zealand",
    url: "/stays-category",
  },
  {
    title: "South Island",
  },
];

const options = [
  {
    title: "Superhost",
    icon: "home",
  },
  {
    title: "Queenstown, Otago, New Zealand",
    icon: "flag",
  },
];

const StaysProduct = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [otherBrandsSameCategory, setOtherBrandsSameCategory] = useState([]);
  const [brandsCount, setBrandsCount] = useState();
  const [brand, setBrand] = useState(null);

  const fetchCategories = async () => {
    setIsLoading(true);
    const result = await categoryService.getCategories();
    setCategories(result);
    if (categories) setIsLoading(false);
  };

  const { id } = useParams();

  const fetchBrand = async () => {
    const result = await brandService.getBrandById(id);
    setBrand(result);
  };

  const fetchOtherBrandsSameCategory = async () => {
    const result = await brandService.getAllBrandByCategoryId(
      brand?.category[0]._id
    );
    if (result) {
      const data = result.data.filter((item) => item._id !== brand._id);
      setOtherBrandsSameCategory(data);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchBrand();
  }, [id]);

  useEffect(() => {
    fetchOtherBrandsSameCategory();
    if (brand) filterCategory();
  }, [brand]);

  console.log("categories : ", categories);

  const fetchCategoryDetail = async () => {
    if (brand) {
      const res = await categoryService.getCategoryById(brand.category[0]._id);

      if (res) setBrandsCount(res.count);
    }
  };
  useEffect(() => {
    fetchCategoryDetail();
  }, []);

  const filterCategory = () => {
    if (categories) {
      const filteredCategories = categories?.filter(
        (category) =>
          category._id !== brand.category[0]._id && category.count !== 0
      );

      if (filteredCategories) setCategories(filteredCategories);
    }
  };

  return (
    <>
      <Product
        classSection="section-mb64"
        urlHome="/"
        breadcrumbs={breadcrumbs}
        options={options}
        type="stays"
        title={brand?.name}
        categories={brand?.category}
      />

      {!isLoading ? (
        <Description classSection="section" item={brand} />
      ) : (
        <div className={styles.loading}>
          <button className={cn("button-stroke", styles.button)}>
            <Loader className={styles.loader} />
            <span>Yükleniyor...</span>
          </button>
        </div>
      )}

      <Browse
        classSection="section"
        headSmall
        classTitle="h4"
        title={
          brandsCount === 1
            ? `${brand?.category[0].name} Kategorisinde Başka Marka Bulunmamaktadır`
            : `${brand?.category[0].name} Kategorisindeki Diğer Markalar`
        }
        items={otherBrandsSameCategory}
      />

      {/* <BrowseBoykot
        classSection="section"
        headSmall
        classTitle="h4"
        title="Firma Boykot"
        items={browse2}
      /> */}

      <Categories
        classSection="section"
        classTitle="h4"
        title="Diğer Kategoriler"
        // info="A lot of amazing experiences 🏝"
        items={categories}
      />
    </>
  );
};

export default StaysProduct;
