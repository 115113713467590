import React, { useState } from "react";
import cn from "classnames";
import styles from "./Sorting.module.sass";
import Dropdown from "../Dropdown2/index";
import Control from "../Control";
import Icon from "../Icon";

const Sorting = ({
  className,
  urlHome,
  breadcrumbs,
  navigation,
  title,
  count,
  info,
  sorting,
  setSorting,
  sortingOptions,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleMap, setVisibleMap] = useState(false);

  const categoriesOptions = [];
  navigation.map((x) => categoriesOptions.push(x));
  const [categories, setCategories] = useState(categoriesOptions[0]);

  return (
    <div className={cn(className, styles.sorting)}>
      <div className={cn("container", styles.container)}>
        <Control
          className={styles.control}
          urlHome={urlHome}
          breadcrumbs={breadcrumbs}
        />
        <div className={styles.body}>
          <div className={styles.box}>
            <div className={cn("h2", styles.title)}>{title}</div>

            {info && <div className={styles.info}>{info}</div>}
          </div>
          <div className={styles.location}>
            <span className={styles.brandCount}>{count} Marka</span>
          </div>
        </div>

        <div className={styles.foot}>
          <div className={styles.nav}>
            {navigation.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>

          <Dropdown
            className={styles.dropdown}
            value={navigation[activeIndex]}
            setValue={setActiveIndex}
            options={navigation}
          />
        </div>
      </div>
    </div>
  );
};

export default Sorting;
