import React from "react";
import useDarkMode from "use-dark-mode";

const Image = ({ className, src, srcDark, srcSet, srcSetDark, alt }) => {

  return (
    <img
      className={className}
      src={src}
      alt={alt}
    />
  );
};

export default Image;
