import React, { useEffect, useState } from "react";
import Main from "./Main2/index";
import Catalog from "./Catalog";
import Categories from "../../components/Categories";
import { useParams } from "react-router";
import categoryService from "../../services/CategoryService";

const Cars = () => {
  const { id } = useParams();

  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    const result = await categoryService.getCategories();
    setCategories(result);
  };
  const fetchCategory = async () => {
    let result;
    if (id) {
      result = await categoryService.getCategoryById(id);
    } else {
      result = await categoryService.getCategories();
      
    }
    setCategory(result);
  };

  useEffect(() => {
    fetchCategory();
  }, [id]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <Main categories={categories} />
      <Catalog title={category?.name} count={category?.count || 0} />
      {/* <Browse
        classSection="section"
        classTitle="h2"
        title="Discover more"
        info="for car rentals"
        items={browse3}
      /> */}

      <Categories
        classSection="section"
        title="Diğer Kategoriler"
        items={categories}
      />
    </>
  );
};

export default Cars;
