import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Location.module.sass";
import Icon from "../Icon";
import { Link } from "react-router-dom";

const Location = ({
  className,
  icon,
  keyword,
  setKeyword,
  brands,
  description,
  placeholder,
  small,
  visible,
  setVisible,
  bodyDown,
  fetchSearch,
  activeButton,
  setBrands,
}) => {
  const debounce = useRef(null);

  useEffect(() => {
    if (keyword.length === 0) {
      setBrands([]);
    }
  }, [keyword]);
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(
          className,
          styles.location,
          { [styles.small]: small },
          { [styles.bodyDown]: bodyDown },
          { [styles.active]: visible }
        )}
      >
        <div className={styles.head}>
          
          <input
            className={styles.input}
            type="text"
            value={keyword}
            placeholder={placeholder}
            onChange={(e) => {
              setKeyword(e.target.value);

              if (debounce.current) {
                clearTimeout(debounce.current);
              }
              debounce.current = setTimeout(() => {
                fetchSearch(e.target.value);
              }, 500);
            }}
          />
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          {keyword.length > 0 && (
            <button className={styles.clear} onClick={() => setVisible(false)}>
              <Icon name="close-circle" size="24" />
            </button>
          )}
        </div>

        {keyword.length > 0 && (
          <div className={styles.body}>
            <div className={styles.list}>
              {activeButton === 0
                ? brands?.data?.map((x, index) => (
                    <Link
                      to={`/marka/${x?._id}`}
                      className={styles.item}
                      key={index}
                    >
                      <img src={x?.logo} className={styles.imgSize} />
                      {x?.name}
                    </Link>
                  ))
                : brands?.map((x, index) => (
                    <Link
                      to={`/kategori/${x?._id}`}
                      className={styles.item}
                      key={index}
                    >
                      <img src={x?.logo} className={styles.imgSize} />
                      {x?.name}
                    </Link>
                  ))}
              {brands?.data?.length < 1 &&
                keyword.length > 0 &&
                activeButton === 0 && (
                  <span className={styles.item}>
                    Aradığınız marka bulunamadı
                  </span>
                )}
              {brands?.length < 1 &&
                keyword.length > 0 &&
                activeButton === 1 && (
                  <span>Aradığınız kategori bulunamadı</span>
                )}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Location;
